import { Approval } from './src/approval';
import { CaseNoteLines } from './src/case_note_lines';
import { ChatReferral } from './src/chat_referral';
import { ClosureReason } from './src/closure_reason';
import { ServiceLogs } from './src/service_logs';
import { CaseNote } from './src/case_note';
import { CaseFile } from './src/case_file';

$(document).ready(function() {
  Approval.setup();
  CaseNoteLines.setup();
  ChatReferral.setup();
  ClosureReason.setup();
  ServiceLogs.setup();
  CaseNote.setup();
  CaseFile.setup();
});
